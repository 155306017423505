<template>
  <div>
    <a href="javascript:void(0)" @click="openModal" class="link" v-if="type === 1">New Booking</a>
    <a href="javascript:void(0)" style="color: #a7a7a7" @click="openModal" class="link" v-if="type === 4">
      <font-awesome-icon icon="plus-circle" class="mr-2"></font-awesome-icon>Special Booking
    </a>
    <button class="btn btn-outline-secondary mr-2" @click="openModal" v-if="type === 2">
      <font-awesome-icon class="mr-1" icon="plus-circle" />New Booking
    </button>
    <button class="btn btn-secondary mr-2" @click="openModal" v-if="type === 5">
      New Booking
    </button>
    <a href="javascript:void(0)" @click="openModal" v-if="type === 3">
      <img src="@/assets/images/icon_edit.svg" class="mr-2" />Edit
    </a>
    <b-modal v-model="isShow" :hide-footer="true" :title="modalTitle" size="lg" centered>
      <LoadingSpinner v-if="isLoading" />
      <div class="row" v-if="!isLoading">
        <div class="col-md-6 border-right">
          <div class="form-group row" v-if="bookingData">
            <div class="col-3">
              <label for="id">ID</label>
            </div>
            <div class="col-9 d-flex flex-space-between">
              <span>{{ bookingData.bookid }}</span>
              <router-link :to="`/customers?search=${email}`" class="link-underline mr-2">Go to Customer
                Profile</router-link>
            </div>
          </div>
          <div class="form-group row" v-if="bookingData">
            <div class="col-3">
              <label>Booking Status</label>
            </div>
            <div class="col-9">
              <v-select class="custom" placeholder="Booking Status"
                :options="$store.state.booking.bookingList.booking_status" label="name" v-model="selectedStatus"
                @input="selectStatus">
                <template v-slot:selected-option="option">
                  <BookingStatus :statusName="option.name" />
                </template>
                <template v-slot:option="option">
                  <BookingStatus :statusName="option.name" />
                </template>
              </v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Name*</label>
            <div class="col-9">
              <input type="text" class="form-control" value="Marc Marcusse" v-model="fullname" placeholder="Full Name" />
              <small class="text-red" v-if="error.fullname">Full name is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Country*</label>
            <div class="col-9">
              <v-select class="custom" placeholder="Country" :options="$store.state.booking.parameterList.countries"
                :clearable="false" label="name" @input="selectCountry" v-model="country"></v-select>
              <small class="text-red" v-if="error.country">Country is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Email*</label>
            <div class="col-9">
              <input type="text" class="form-control" v-model="email" placeholder="Email" />
              <small class="text-red" v-if="error.email">Email is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Phone*</label>
            <!-- <div class="col-2 pr-0">
              <input
                type="text"
                class="form-control"
                v-model="pcode"
                placeholder="+00"
              />
              <small class="text-red" v-if="error.pcode">Required</small>
            </div> -->
            <div class="col-9">
              <input type="text" class="form-control" v-model="phone" placeholder="Phone Number" />
              <small class="text-red" v-if="error.phone">Phone number is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Hotel*</label>
            <div class="col-9">
              <input type="text" class="form-control" v-model="hotel" placeholder="Hotel" v-if="!hotelcheck" />
              <small class="text-red" v-if="error.hotel">Hotel is required</small>
              <div class="mt-2">
                <Checkbox v-model="hotelcheck" />
                <span class="text-muted">Not booked yet</span>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">TC</label>
            <div class="col-9">
              <v-select class="custom" placeholder="Direct" :options="$store.state.booking.parameterList.transactions"
                label="tranname" v-model="btsource" @input="selectTC"></v-select>
              <small class="text-red" v-if="error.btsource">TC is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Source</label>
            <div class="col-9">
              <v-select class="custom" placeholder="System" :options="$store.state.booking.bookingSourceList"
                label="stranname" v-model="btssource" @input="selectSource"></v-select>
              <small class="text-red" v-if="error.btssource">Source is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Channel</label>
            <div class="col-9">
              <v-select class="custom" placeholder="Facebook" :options="channelList" v-model="channel"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Remark</label>
            <div class="col-9">
              <textarea id rows="3" class="form-control" v-model="remark" placeholder="Remark">
                Remark from booking system
              </textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Booking Ref.</label>
            <div class="col-9">
              <input type="text" class="form-control" v-model="bookingRef" placeholder="Booking Reference" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Attachment</label>
            <div class="col-9">
              <FileUpload v-for="index in totalFiles" @uploaded="getFile" @removed="removeFile" :key="index"
                ref="fileupload" :maxfiles="maxFiles" :index="index - 1" />
              <div class="mt-3" v-for="file in files" :key="file.id">
                <span class="text-muted mr-2">{{ file.filename }}</span>
                <a :href="`${file.path_file}`" target="_blank" class="link-underline text-yellow">See
                  File</a>
                <a href="javascript:void(0)" class="ml-2 text-yellow" @click="deleteFile(file.id)">
                  <font-awesome-icon icon="times-circle"></font-awesome-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="mt-5 mb-5">
            <BookingSimcard :booking="bookingData" ref="bookingSimcard" @addSim="updateSim($event, 'added')"
              @removeSim="updateSim($event, 'removed')" @removePhone="removePhone($event)" />
          </div>
          <div class="mt-5 mb-5">
            <BookingLog />
          </div>
          <div class="cancel-btn" v-if="bookingData && bookingData.bookid">
            <a href="javascript:void(0)" class="link-underline" @click="openConfirmModal()">Delete Booking</a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-form-label col-3">Date*</label>
            <div class="col-9">
              <date-picker v-model="datepick" :disabled-date="disabledDate" :disabled="hasPromocode"
                :format="'DD/MM/YYYY'" :editable="false" placeholder="Date"></date-picker>
              <small class="text-red" v-if="error.datepick">Date is required</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3">Tour*</label>
            <div class="col-9">
              <v-select class="custom" placeholder="Tour" :options="$store.state.booking.parameterList.tour_lists"
                :clearable="false" label="full_tourname" v-model="tour" @input="selectTour"
                :disabled="validateTourDisable">
                <template v-slot:selected-option="option">{{ option.code }} - {{ option.start_time }}-{{
                  option.end_time
                }}
                  {{ option.tourname }}</template>
                <template v-slot:option="option">{{ option.code }} - {{ option.start_time }}-{{
                  option.end_time
                }}
                  {{ option.tourname }}</template>
              </v-select>
              <small class="text-red" v-if="error.tour">Tour is required</small>
            </div>
          </div>

          <!-- Special Tour -->

          <div v-if="isSpecialBooking">
            <div class="form-group row">
              <label class="col-3 col-form-label">Tour Name</label>
              <div class="col-9">
                <input type="text" class="form-control" placeholder="Tour Name" v-model="tourName" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3 col-form-label">Location*</label>
              <div class="col-9">
                <v-select class="custom" placeholder="Location" :options="$store.state.tour.parameterList.location"
                  :clearable="false" label="locate_name" v-model="location"></v-select>
                <small class="text-red" v-if="error.location">Location address is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3">Tour Time*</label>
              <div class="col-9">
                <v-select class="custom" placeholder="Tour Time" :options="$store.state.tour.parameterList.time"
                  :clearable="false" label="name" v-model="tourTime"></v-select>
                <small class="text-red" v-if="error.datepick">Tour time is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3 col-form-label">Departure*</label>
              <div class="col-9">
                <v-select class="custom" placeholder="Departure Time" :options="timeList" v-model="startTime"
                  @input="calculateDuration('start_time')"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3 col-form-label">Finish*</label>
              <div class="col-9">
                <v-select class="custom" placeholder="Finish Time" :options="endTimeList" v-model="endTime"
                  @input="calculateDuration('end_time')"></v-select>
              </div>
            </div>
            <div class="form-group row mt-3">
              <label class="col-3">Duration</label>
              <div class="col-9">{{ duration || 0 }} Hours</div>
            </div>
            <div class="form-group row">
              <label class="col-3 col-form-label">Guaranteed Credit*</label>
              <div class="col-9">
                <input type="text" class="form-control" v-model="guaranteedCredit" />
              </div>
            </div>
          </div>

          <!-- End Special Tour -->
          <div class="form-group row mt-3" v-if="bookingData">
            <label class="col-3">Payment Status</label>
            <div class="col-9">
              <PaymentStatus :statusName="payment_status" />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label class="col-3">Total PAX</label>
            <div class="col-9 people-count">
              {{ total_pax }}
              <div class="row mt-2">
                <div class="col-6">
                  <label>Adults</label>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('ad', 'remove')">
                    <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                  </a>
                  <span class="count">{{ adultCount }}</span>
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('ad', 'add')">
                    <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label>Children</label>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('ch', 'remove')">
                    <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                  </a>
                  <span class="count">{{ childrenCount }}</span>
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('ch', 'add')">
                    <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label>Child Seats</label>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('cs', 'remove')">
                    <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                  </a>
                  <span class="count">{{ childrenSeatCount }}</span>
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('cs', 'add')">
                    <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label>Baby Seats</label>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('bs', 'remove')">
                    <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                  </a>
                  <span class="count">{{ babySeatCount }}</span>
                  <a href="javascript:void(0)" v-if="!hasPromocode" @click="changeSeatCount('bs', 'add')">
                    <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                  </a>
                </div>
              </div>
              <small class="text-red" v-if="error.pax">Pax can not be 0</small>
            </div>
          </div>

          <!-- Summary section -->
          <hr />
          <BookingPriceSummary :tour="tour" :total_net="total_net()" :total_amount="total_amount()"
            :combineHistoryAndAmountList="combineHistoryAndAmountList" v-if="checkAgentRatePermission()" />

          <div class="text-right" v-if="payment_status === 'Fully Paid' ||
            payment_status === 'Refund-pending' ||
            payment_status === 'Refund-request'
            ">
            <Checkbox @input="checkRefund" v-model="isRefund" />
            <span class="text-muted">Refund</span>
          </div>

          <div class="form-group mb-3" v-if="isRefund">
            <label>Refund Reason*</label>
            <textarea rows="3" class="form-control" v-model="refundRemark"></textarea>
          </div>

          <div class="row mt-3" v-if="payment_status !== 'Free of Charge (Pending)' &&
            payment_status !== 'Refund-Success'
            ">
            <div class="col-6">
              <BookingSurchargeModal v-if="!hasPromocode" @addSurcharge="updateExtraPriceList" :totalNet="total_net()"
                :combineHistoryAndAmountList="this.combineHistoryAndAmountList" />
            </div>
            <div class="col-6">
              <BookingDeductModal @updateFOC="updateFOCStatus" @addDeduct="updateExtraPriceList" :totalNet="total_net()"
                v-if="!hasPromocode" :isDisabledFOC="payment_status === 'Partial'"
                :combineHistoryAndAmountList="this.combineHistoryAndAmountList" />
            </div>
          </div>

          <!-- Update booking -->

          <div class="modal-footer d-block mt-3" v-if="bookingData && payment_status !== 'Free of Charge (Pending)'">
            <!-- TODO: Change to status id instead of name -->
            <BookingPayModal ref="bookingPayModal" :bookid="bookingData ? bookingData.bookid : null" :amount="total_net()"
              @closeModal="closeModal" @updatePayment="updatePayment" :isBookingFormOpened="true" :isRefund="isRefund"
              :total_amount="total_amount()" :payments_sum="payments_sum()" />
            <LoadingSpinner v-if="isLoadingAvailability" />
            <div class="text-right mb-2" v-if="!isLoadingAvailability">
              <span v-bind:class="{
                'text-green': isAvailable,
                'text-red': !isAvailable,
              }">{{ bookingAvailabilityMsg }}</span>
            </div>
            <div v-if="!isLoadingAvailability">
              <vue-ladda button-class="btn btn-secondary w-100" :loading="isSubmitRefund" @click="markAsPaid"
                :disabled="!isAvailable" v-if="hasAccess('approve_refund') &&
                  bookingData &&
                  isRefund &&
                  bookingData.payment_type_name == 'Refund-pending'
                  ">Mark as Refunded</vue-ladda>
              <button class="btn btn-secondary w-100" :disabled="!isAvailable" @click="markAsPaid"
                v-if="!isRefund && !payment_status !== 'Refund-pending'">
                Add Payment
              </button>
              <vue-ladda button-class="btn btn-outline-secondary w-100 mt-3" :loading="isLoadingWithoutPaid"
                :disabled="!isAvailable" @click="submit">Update Booking</vue-ladda>
            </div>

            <div class="mt-3 text-right" v-if="isShowSendEmail">
              <Checkbox v-model="isSendEmail" />
              <span class="text-muted">Send update booking email</span>
            </div>
          </div>

          <!-- FOC Approval -->

          <div class="modal-footer d-block mt-3" v-if="bookingData && payment_status === 'Free of Charge (Pending)'">
            <div>
              <vue-ladda button-class="btn btn-secondary w-100" :loading="isLoadingApproveFOC" @click="submitFOC(true)"
                v-if="hasAccess('approve_foc')">Approve FOC</vue-ladda>
            </div>
            <div class="mt-3">
              <vue-ladda button-class="btn btn-outline-secondary w-100" :loading="isLoadingRejectFOC"
                v-if="hasAccess('approve_foc')" @click="submitFOC(false)">Reject FOC</vue-ladda>
            </div>
          </div>

          <!-- Create New Booking -->

          <div class="modal-footer d-block mt-3" v-if="!bookingData">
            <BookingPayModal ref="bookingPayModal" @closeModal="closeModal" @updatePayment="updatePayment"
              :fullname="fullname" :adults="adultCount" :children="childrenCount" :childrenseat="childrenSeatCount"
              :babyseat="babySeatCount" :amount="total_net()" :tourName="tour ? tour.full_tourname : null"
              :isAgentBooking="btsource && btsource.code === 'AG'" :isRefund="isRefund" :total_amount="total_amount()" />
            <div class="text-center">
              <LoadingSpinner v-if="isLoadingAvailability" />
              <div class="row" v-if="!isLoadingAvailability">
                <div class="col-6">
                  <div class="mb-2 text-left" v-if="!this.bookingData">
                    <Checkbox v-model="donotSendEmail" />
                    <span class="text-muted">Don't send email</span>
                  </div>
                </div>
                <div class="col-6 mb-2 text-right">
                  <span :class="{
                    'text-green': isAvailable,
                    'text-red': !isAvailable,
                  }">{{ bookingAvailabilityMsg }}</span>
                </div>
              </div>
              <button class="btn btn-secondary w-100" :disabled="!isAvailable" @click="bookAndPay"
                v-if="!isLoadingAvailability">
                Book & Pay Now
              </button>
            </div>
            <div class="text-center mt-3" v-if="!isLoadingAvailability">
              <vue-ladda button-class="btn btn-outline-secondary w-100" @click="submit" :loading="isLoadingWithoutPaid"
                :disabled="!isAvailable">Book Only</vue-ladda>
            </div>
          </div>
        </div>
      </div>
      <ApproveConfirmationModal ref="confirmModal" :isLoading="isLoadingDelete"
        :id="bookingData ? bookingData.bookid : null" @submit="deleteBooking" :title="'Delete Confirmation'"
        :body="'Are you sure you want to delete this booking?'" :button="'Delete Booking'" />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { sumBy, isEmpty, clone, forEach } from "lodash";
import BookingDeductModal from "@/components/bookings/BookingDeductModal";
import BookingSurchargeModal from "@/components/bookings/BookingSurchargeModal";
import BookingSimcard from "@/components/bookings/BookingSimcard";
import BookingLog from "@/components/bookings/BookingLog";
import BookingPriceSummary from "@/components/bookings/BookingPriceSummary";
import PaymentStatus from "@/components/commons/PaymentStatus";
import Notification from "@/services/notificationService";
import LoadingSpinner from "@/components/commons/LoadingSpinner";
import BookingStatus from "@/components/commons/BookingStatus";
import Checkbox from "@/components/commons/Checkbox";
import ApproveConfirmationModal from "@/components/commons/ApproveConfirmationModal";
import Permission from "@/services/permissionService";
import FileUpload from "@/components/commons/FileUpload";
import CONSTANTS from "@/constants/shared";

export default {
  data() {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      isShow: false,
      fullname: "",
      country: null,
      email: "",
      pcode: "",
      phone: "",
      hotel: "",
      btsource: null,
      btssource: null,
      channel: null,
      remark: "",
      datepick: new Date(),
      tour: null,
      adultCount: 0,
      childrenCount: 0,
      childrenSeatCount: 0,
      babySeatCount: 0,
      amount: 0,
      totalAmount: 0,
      hotelcheck: false,
      bookingRef: null,
      bookingFile: [],
      files: [],
      maxFiles: CONSTANTS.total_fileuploads,
      totalFiles: CONSTANTS.total_fileuploads,
      deleteFileIds: [],
      isLoadingWithoutPaid: false,
      extraAmountList: [],
      extraHistoryList: [],
      error: {},
      modalTitle: "New Booking",
      bookingHistory: {},
      isLoading: false,
      isLoadingDelete: false,
      selectedStatus: null,
      paymentList: [],
      channelList: [
        "Tripadvisor",
        "Facebook",
        "Instagram",
        "Twitter",
        "YouTube",
        "BLOG",
        "Newspaper/Magazine article",
        "Google Search",
        "Friend recommended",
        "Fellow traveler recommended",
        "Hotel concierge recommended",
        "Other",
      ],
      isRefund: false,
      dynamicPayment: "",
      refundRemark: "",
      isSubmitRefund: false,
      isFOC: false,
      isLoadingApproveFOC: false,
      isLoadingRejectFOC: false,
      originalPaymentID: null,
      donotSendEmail: false,
      isShowSendEmail: false,
      // Special Booking

      tourTime: null,
      startTime: null,
      endTime: null,
      transportation: null,
      tourName: "",
      timeList: [],
      endTimeList: [],
      location: null,
      guaranteedCredit: 1,
      duration: 0,
      removedList: [],
      isSendEmail: false,
      debounce: null,
      isBookingForAgent: false,
    };
  },
  components: {
    BookingDeductModal,
    BookingSurchargeModal,
    BookingPayModal: () => import("@/components/bookings/BookingPayModal"),
    BookingSimcard,
    BookingLog,
    LoadingSpinner,
    PaymentStatus,
    BookingStatus,
    ApproveConfirmationModal,
    Checkbox,
    BookingPriceSummary,
    FileUpload,
  },
  props: {
    type: Number,
    paymentStatusName: String,
    bookingData: {
      type: Object,
      default: () => null,
    },
    isRedirect: {
      type: Boolean,
      default: () => true,
    },
    isSpecialBooking: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    isRefund() { },
    datepick(newValue, oldValue) {
      if (!moment(newValue).isSame(moment(oldValue))) {
        if (this.bookingData && this.bookingData.bookid) return;
        this.tour = null;
      }
      this.checkAvailability();
    },
  },
  computed: {
    ...mapState({
      isAvailable: (state) => state.booking.bookingAvailability,
      searchParams: (state) => state.booking.searchParams,
      bookingAvailabilityMsg: (state) => state.booking.bookingAvailabilityMsg,
      isLoadingAvailability: (state) => state.booking.isLoadingAvailability,
      agentList: (state) => state.agent.agentList,
      agent: (state) => state.agent.agentDetail,
      tourType: (state) => state.booking.bookingList.tour_type,
      tourGroupList: (state) => state.tour.allTourList,
    }),
    validateTourDisable() {
      if (this.hasPromocode) return true;

      return false;
    },
    total_pax() {
      return this.adultCount + this.childrenCount;
    },
    combineHistoryAndAmountList() {
      let combinedHistory = this.extraHistoryList.concat(this.extraAmountList);
      return combinedHistory.filter(
        (data) =>
          !(
            data.remark.toLowerCase().indexOf("sim") !== -1 &&
            data.type.toLowerCase() === "surcharge"
          )
      );
    },
    payment_status() {
      return this.dynamicPayment ? this.dynamicPayment : "-";
    },
    hasPromocode() {
      return (
        this.combineHistoryAndAmountList.find(
          (data) => data.type === "Promocode"
        ) !== undefined
      );
    },
  },
  methods: {
    ...mapActions([
      "getBookingDetail",
      "getSimcardList",
      "updateFOC",
      "getBookingLog",
      "getTourParameter",
      "getBookingParameter",
      "checkBookingAvailability",
      "setBookingAvailabilityMsg",
      "getTourGroupList"
    ]),
    checkTourPassedTime(option) {
      const selectedHour = option.start_time.split(":")[0];
      const selectedMinute = option.start_time.split(":")[1];

      const tourStartTime = moment(this.datepick)
        .set("hour", selectedHour)
        .set("minute", selectedMinute);

      const currentTime = moment();

      if (tourStartTime < currentTime) {
        return false;
      }

      return true;
    },
    total_amount() {
      if (this.tour) {
        if (this.isBookingForAgent) {
          return this.calculateAgentPrice(this.adultCount, this.childrenCount);
        } else if (!this.bookingData) {
          // for new created booking
          return (
            this.adultCount * this.tour.adult_price +
            this.childrenCount * this.tour.children_price +
            this.childrenSeatCount * this.tour.childrenseat_price +
            this.babySeatCount * this.tour.babyseat_price
          );
        } else {
          return Number(
            sumBy(
              this.combineHistoryAndAmountList.filter((obj) =>
                ["bill", "change", "Changes"].includes(obj.type)
              ),
              "amount"
            )
          );
        }
      }
      return 0;
    },
    calculateOtherAmounts(combineHistoryAndAmountListParam) {
      let combineHistoryAndAmountList =
        typeof combineHistoryAndAmountListParam === "undefined"
          ? this.combineHistoryAndAmountList
          : combineHistoryAndAmountListParam;
      let totalOfOtherAmuonts = 0;
      let paidAmount = combineHistoryAndAmountList.filter((obj) =>
        ["fee", "payment"].includes(obj.type)
      );
      if (paidAmount.length > 0) {
        totalOfOtherAmuonts = this.total_amount();
      }
      let otherAmounts = combineHistoryAndAmountList.filter((obj) =>
        [
          "surcharge",
          "fee",
          "payment",
          "deduct",
          "Deduct",
          "Surcharge",
          "refund",
          "foc",
        ].includes(obj.type.toLowerCase())
      );
      if (otherAmounts.length > 0) {
        otherAmounts.forEach((otherAmountData) => {
          totalOfOtherAmuonts += otherAmountData.amount;
        });
      }
      return totalOfOtherAmuonts;
    },
    total_net(combineHistoryAndAmountListParam) {
      let totalNet;
      let combineHistoryAndAmountList =
        typeof combineHistoryAndAmountListParam === "undefined"
          ? this.combineHistoryAndAmountList
          : combineHistoryAndAmountListParam;
      // Sim needed only in extra, not in surcharge
      combineHistoryAndAmountList = combineHistoryAndAmountList.filter(
        (data) =>
          !(
            data.remark.toLowerCase().indexOf("sim") !== -1 &&
            data.type.toLowerCase() === "surcharge"
          )
      );
      if (this.bookingData) {
        // calculate balance (apply all events one by one)
        totalNet = Number(sumBy(combineHistoryAndAmountList, "amount"));
      } else {
        totalNet = this.total_amount() + sumBy(this.extraAmountList, "amount");
      }
      let amountToReturn = totalNet;
      return amountToReturn; //+ this.calculateOtherAmounts(combineHistoryAndAmountList)
    },
    payments_sum() {
      return Number(
        sumBy(
          this.combineHistoryAndAmountList.filter((obj) =>
            ["payment", "refund"].includes(obj.type)
          ),
          "amount"
        )
      );
    },
    disabledDate(date) {
      return moment(date).isBefore(moment().subtract(30, "days"));
    },
    selectCountry(country) {
      this.pcode = country ? "+ " + country.phonecode : "";
    },
    bookAndPay() {
      if (!isEmpty(this.validateInput())) {
        return;
      }

      this.$refs.bookingPayModal.isShow = true;
      this.$store.commit("clearBookingDetail");
      this.$store.commit("setBookingDetailLoadingSpinner", false);
    },
    markAsPaid() {
      this.$refs.bookingPayModal.openModal();
    },
    openConfirmModal() {
      this.$refs.confirmModal.openModal();
    },
    removePhone(phone) {
      this.removedList.push(phone);
    },
    calculateAgentPrice(adultCount, childrenCount) {
      const price = this.agent.rate ? this.agent.rate[this.tour.code] : null;
      const adultPrice = price ? price.AD || price : 0;
      const childrenPrice = price ? price.CH || price : 0;

      const totalAdultPrice = adultCount * Number(adultPrice);
      const totalChildrenPrice = childrenCount * Number(childrenPrice);
      return totalAdultPrice + totalChildrenPrice;
    },
    updateSim(sim, changedType) {
      const simChanged = this.extraAmountList.find((ext) => ext.type === "Sim");
      simChanged.amount +=
        changedType === "added" ? Number(sim.price) : -Number(sim.price);
      const simcardList = this.$refs.bookingSimcard.customSimcardList;
      let remarkText = "";
      simcardList
        .filter((s) => s.phones.length)
        .map((s) => {
          remarkText += `${s.name} x ${s.phones.length} `;
        });
      simChanged.remark = remarkText;

      if (simChanged.amount > 0 && this.paymentStatusName !== "Unpaid") {
        this.dynamicPayment = "Partial";
      } else {
        this.dynamicPayment = this.paymentStatusName;
      }
    },
    async openModal(e) {
      this.clearBookingData();
      if (e) {
        e.stopPropagation();
      }
      this.dynamicPayment = this.paymentStatusName;
      this.isShow = true;
      this.isSendEmail = false;
      this.extraAmountList = [];
      this.$store.commit("clearBookingDetail");
      this.calculateTime();

      this.$store.commit("checkBookingAvailability", true);
      this.$store.commit("setBookingAvailabilityMsg", "Available");

      if (!this.bookingData || !this.bookingData.bookid) {
        if (this.isSpecialBooking) {
          this.modalTitle = "New Special Booking";
        }

        this.isLoading = true;
        await Promise.all([
          this.getSimcardList(),
          this.getTourParameter(),
          this.getBookingParameter(),
        ]);
        this.files = this.bookingData;
        // Booking from left menu
        if (this.type === 1) {
          this.datepick = null;
        } else {
          if (this.$route.query.date) {
            this.datepick = new Date(this.$route.query.date);
          }

          if (this.$route.query.tour_id && this.$route.query.date) {
            const params = {
              date: this.$route.query.date,
            };
            await this.getTourGroupList(params);
            this.tour = Number(this.$route.query.tour_id)
          }
        }

        // Book from tour page directly
        if (this.type === 2) {
          const tourList = this.$store.state.booking.parameterList.tour_lists;
          const tour = tourList.find(
            (tour) =>
              tour.code === this.tourType.code &&
              tour.time_type === this.tourType.time_type
          );
          this.tour = tour;
        } else {
          this.tour = null;
        }
        this.isLoading = false;
      }

      this.$store.commit("clearBookingLog");
      if (this.bookingData && this.bookingData.bookid) {
        this.modalBooking = true;
        this.modalTitle = "Edit Booking";
        this.isLoading = true;
        try {
          await Promise.all([
            this.getBookingDetail(this.bookingData.bookid),
            this.getBookingLog(this.bookingData.bookid),
            this.getSimcardList(),
            this.getTourParameter(),
            this.getBookingParameter({
              date: this.$route.query.date,
              is_include_special_tour: this.bookingData.special_tour,
              bookid: this.bookingData.bookid,
            }),
          ]);
        } catch (error) {
          Notification.error(error);
        }
        this.isLoading = false;
        const bookingData = clone(this.$store.state.booking.bookingDetail);
        this.fullname = bookingData.fullname;
        this.country = this.$store.state.booking.parameterList.countries.find(
          (obj) => obj.name === bookingData.country
        );
        this.email = bookingData.email;
        this.pcode = bookingData.phone_country_code;
        this.phone = bookingData.phone;
        this.hotel = bookingData.hotelname;
        this.btsource =
          this.$store.state.booking.parameterList.transactions.find(
            (obj) => obj.id === Number(bookingData.btsource)
          );
        this.btssource =
          this.$store.state.booking.parameterList.subtransactions.find(
            (obj) => obj.id === Number(bookingData.btssource)
          );
        this.selectSource(this.btssource, false);
        this.channel = bookingData.channel;
        this.remark = bookingData.remark ? bookingData.remark : "";
        this.datepick = new Date(bookingData.datepick);
        this.hotelcheck = bookingData.hotelcheck === 1;
        this.tour = this.$store.state.booking.parameterList.tour_lists.find(
          (obj) => obj.id === bookingData.tour_id
        );
        this.bookingRef = bookingData.booking_ref;
        this.isRefund = this.payment_status.includes("Refund");
        this.adultCount = bookingData.adults;
        this.childrenCount = bookingData.children;
        this.childrenSeatCount = bookingData.children_seat;
        this.babySeatCount = bookingData.baby_seat;
        this.status = bookingData.book_status;
        this.amount = bookingData.amount;
        this.totalAmount = bookingData.total_amount;
        this.refundRemark = bookingData.refund_reason;
        this.originalPaymentID = bookingData.payment_type_id;
        this.files = bookingData.files;
        this.maxFiles = this.maxFiles - this.files.length;
        this.isShowSendEmail = bookingData.book_status == 1 ? true : false;


        // Special Booking
        if (this.isSpecialBooking) {
          this.tourName = bookingData.tour_name;
          this.location = bookingData.location;
          this.location = this.$store.state.tour.parameterList.location.find(
            (obj) => obj.id === bookingData.location_id
          );
          this.tourTime = this.$store.state.tour.parameterList.time.find(
            (obj) => obj.id === bookingData.time_type
          );

          this.startTime = bookingData.start_time;
          this.endTime = bookingData.end_time;
          const timeDiff = moment(bookingData.end_time, "HH:mm").diff(
            moment(bookingData.start_time, "HH:mm")
          );
          this.duration = moment.utc(timeDiff).format("H:mm");
        }

        // End Speical Booking

        this.selectedStatus =
          this.$store.state.booking.bookingList.booking_status.find(
            (obj) => obj.id === bookingData.book_status
          );

        this.extraHistoryList = bookingData.payment_list;

        if (this.bookingData) {
          this.extraAmountList = [
            ...this.extraAmountList,
            {
              type: "Changes",
              amount: 0,
              remark: "",
              ad: 0,
              ch: 0,
              cs: 0,
              bs: 0,
              is_temporary: true,
            },
          ];
        }
      }
      this.extraAmountList.push({
        type: "Sim",
        amount: 0,
        remark: "",
        is_temporary: true,
      });
    },
    changeSeatCount(seatType, changeType) {
      this.checkAvailability();
      switch (seatType) {
        case "ad":
          if (changeType === "remove" && this.adultCount === 0) {
            return;
          }
          changeType === "add" ? this.adultCount++ : this.adultCount--;
          break;
        case "ch":
          if (changeType === "remove" && this.childrenCount === 0) {
            return;
          }
          changeType === "add" ? this.childrenCount++ : this.childrenCount--;
          break;
        case "cs":
          if (changeType === "remove" && this.childrenSeatCount === 0) {
            return;
          }
          changeType === "add"
            ? this.childrenSeatCount++
            : this.childrenSeatCount--;
          break;
        case "bs":
          if (changeType === "remove" && this.babySeatCount === 0) {
            return;
          }
          changeType === "add" ? this.babySeatCount++ : this.babySeatCount--;
          break;
        default:
          return;
      }

      if (this.bookingData) {
        this.changePax(seatType, changeType);
      }
    },
    updateFOCStatus(isFOC) {
      this.isFOC = isFOC;
    },
    changePax(seatType, changeType) {
      const tempIndex = this.extraAmountList.findIndex(
        (ext) => ext.type === "Changes" && ext.is_temporary
      );
      const tempObj = this.extraAmountList[tempIndex];

      switch (changeType) {
        case "add":
          tempObj[seatType] += 1;
          break;
        case "remove":
          tempObj[seatType] -= 1;
          break;
      }

      tempObj.remark = `PAX Changed: ${tempObj["ad"]} AD, ${tempObj["ch"]} CH, ${tempObj["cs"]} CS, ${tempObj["bs"]} BS`;

      if (this.isBookingForAgent) {
        tempObj.amount = this.calculateAgentPrice(tempObj.ad, tempObj.ch);
      } else {
        tempObj.amount =
          tempObj.ad * this.tour.adult_price +
          tempObj.ch * this.tour.children_price +
          tempObj.cs * this.tour.childrenseat_price +
          tempObj.bs * this.tour.babyseat_price;
      }

      if (tempObj.amount > 0 && this.paymentStatusName !== "Unpaid") {
        this.dynamicPayment = "Partial";
      } else {
        this.dynamicPayment = this.paymentStatusName;
      }
    },
    selectTour(tour) {
      if (this.bookingData) {
        const changesIndex = this.extraAmountList.findIndex(
          (ext) =>
            ext.type === "Changes" &&
            ext.is_temporary &&
            ext.remark === "Change Tour"
        );

        if (changesIndex !== -1) {
          this.extraAmountList.splice(changesIndex, 1);
        }

        if (this.bookingData.tour_code !== tour.code) {
          const newTotalPrice =
            this.adultCount * tour.adult_price +
            this.childrenCount * tour.children_price +
            this.childrenSeatCount * tour.childrenseat_price +
            this.babySeatCount * tour.babyseat_price;

          const changePrice = newTotalPrice - this.total_amount();
          if (0 !== changePrice) {
            this.extraAmountList.push({
              type: "Changes",
              amount: changePrice,
              remark: "Change Tour",
              is_temporary: true,
            });
          }

        }

      }

      this.checkAvailability();
    },
    checkRefund(value) {
      if (value) {
        this.dynamicPayment = "Refund-pending";
      } else {
        this.dynamicPayment = "Fully Paid";
      }
    },
    closeModal() {
      this.$refs.bookingPayModal.closeModal();
      this.isShow = false;
    },
    updatePayment(paymentList) {
      this.paymentList = paymentList;
      this.isRefund ? this.submit("refund") : this.submit();
    },
    updateExtraPriceList(data) {
      this.extraAmountList.push(data);
    },
    checkAvailability() {
      if (!this.datepick || !this.tour) {
        return;
      }
      let isAvailable = true;

      this.tour.tour_exclude_date &&
        this.tour.tour_exclude_date.every((item) => {
          if (
            moment(this.datepick).isBetween(
              item.start_date,
              item.end_date,
              null,
              []
            )
          ) {
            isAvailable = false;
            return false;
          }
          return true;
        });

      this.$store.commit("checkBookingAvailability", false);
      this.$store.commit(
        "setBookingAvailabilityMsg",
        isAvailable ? "Available" : "Unavailable"
      );

      if (!isAvailable) return;

      if (this.btssource) {
        if (
          this.agent &&
          this.agent.rate &&
          this.agent.rate[this.tour.code] &&
          this.agent.rate[this.tour.code].AD == 0
        ) {
          this.$store.commit("checkBookingAvailability", false);
          this.$store.commit("setBookingAvailabilityMsg", "Unavailable");
          return;
        }
      }



      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const params = {
          date: moment(this.datepick).format("YYYY-MM-DD"),
          tour_id: this.tour ? this.tour.id : "",
          pax: this.total_pax,
          is_special: this.isSpecialBooking,
        };
        if (this.tour && this.datepick && this.total_pax > 0) {
          await this.checkBookingAvailability(params);
        }
      }, 600);
    },

    hasAccess(name) {
      return Permission.hasAccess(name);
    },
    getFile(file, index) {
      this.bookingFile[index] = file;
      this.maxFiles--;
      this.showFileUpload();
    },
    removeFile(index) {
      const fileuploadLen = this.$refs.fileupload.length;
      this.bookingFile.splice(index, 1);
      for (let i = index; i < fileuploadLen - 1; i++) {
        this.$refs.fileupload[i].contractName =
          this.$refs.fileupload[i + 1].contractName;
        this.$refs.fileupload[i].contractFile =
          this.$refs.fileupload[i + 1].contractFile;
      }
      this.maxFiles++;
      this.showFileUpload();
    },
    deleteFile(id) {
      this.deleteFileIds.push(id);
      this.files = this.files.filter((e) => e.id != id);
      this.maxFiles++;
      this.showFileUpload();
    },
    showFileUpload() {
      if (this.$refs.fileupload) {
        [...Array(this.totalFiles)].forEach((_, index) => {
          this.$refs.fileupload[index].show = false;
          if (this.bookingFile[index]) {
            this.$refs.fileupload[index].show = true;
            return;
          }
        });
        if (this.maxFiles)
          this.$refs.fileupload[this.bookingFile.length].show = true;
      }
    },
    validateInput() {
      this.error = {};
      this.fullname
        ? delete this.error["fullname"]
        : (this.error["fullname"] = true);
      this.country
        ? delete this.error["country"]
        : (this.error["country"] = true);
      this.email ? delete this.error["email"] : (this.error["email"] = true);
      // this.pcode ? delete this.error["pcode"] : (this.error["pcode"] = true);
      this.phone ? delete this.error["phone"] : (this.error["phone"] = true);
      if (!this.hotelcheck) {
        this.hotel ? delete this.error["hotel"] : (this.error["hotel"] = true);
      }
      this.tour ? delete this.error["tour"] : (this.error["tour"] = true);
      this.datepick
        ? delete this.error["datepick"]
        : (this.error["datepick"] = true);
      this.total_pax === 0
        ? (this.error["pax"] = true)
        : delete this.error["pax"];
      return this.error;
    },
    async submit() {
      if (!isEmpty(this.validateInput())) {
        setTimeout(() => {
          this.$refs.bookingPayModal.isLoading = false;
        }, 10);
        return;
      }

      const fd = new FormData();
      fd.append("fullname", this.fullname);
      fd.append("country", this.country.name);
      fd.append("email", this.email);
      fd.append("pcode", this.pcode);
      fd.append("phone", this.phone);
      if (!this.hotelcheck) {
        fd.append("hotel", this.hotel ? this.hotel : "");
      }
      fd.append("btsource", this.btsource ? this.btsource.id : "");
      fd.append("btssource", this.btssource ? this.btssource.id : "");
      fd.append("channel", this.channel ? this.channel : "");
      fd.append("remark", this.remark);
      fd.append("booking_ref", this.bookingRef || "");
      fd.append("datepick", moment(this.datepick).format("YYYY-MM-DD"));
      fd.append("tour_id", this.tour.id);
      fd.append("adults", this.adultCount);
      fd.append("children", this.childrenCount);
      fd.append("childrenseat", this.childrenSeatCount);
      fd.append("baby", 0);
      fd.append("babyseat", this.babySeatCount);
      fd.append("total_amount", this.totalAmount || this.total_amount()); // Untouched price
      fd.append("amount", this.total_net()); // Touched price
      fd.append("hotelcheck", this.hotelcheck ? 1 : 0);

      // Attach File
      if (this.bookingFile.length) {
        this.bookingFile.forEach((element) => {
          fd.append("attach_file[]", element);
        });
      }

      if (this.deleteFileIds) {
        fd.append("delete_file", this.deleteFileIds.join(","));
      }

      if (!this.bookingData && this.donotSendEmail) {
        fd.append("no_email", 1);
      }

      const simcardList = this.$refs.bookingSimcard.customSimcardList;
      let count = 0;
      simcardList.map((sim) => {
        sim.phones.map((phone) => {
          if (phone.id) {
            fd.append(`sim_cards[add][${count}][id]`, phone.id);
          } else {
            fd.append(`sim_cards[add][${count}][offer_id]`, sim.id);
          }
          fd.append(`sim_cards[add][${count}][number]`, phone.number);
          count++;
        });
      });

      this.removedList.map((phone) => {
        if (phone && phone.id) {
          fd.append(`sim_cards[remove][]`, phone.id);
        }
      });

      this.paymentList.map((obj) => {
        if (obj.paymentMethod && obj.amount) {
          fd.append("method[]", obj.paymentMethod.id);
          fd.append("payment_note[]", obj.paymentID);
          fd.append("paid[]", obj.amount);
        }
      });

      let newExtraAmountList = this.extraAmountList;
      if (!this.bookingData) {
        newExtraAmountList = this.extraAmountList.filter(
          (ext) => ext.type !== "Sim"
        );
      }

      newExtraAmountList.map((ext) => {
        if (!ext.is_temporary) {
          fd.append("rest[]", ext.type.toLowerCase());
          fd.append("price_rest[]", Math.abs(ext.amount));
          fd.append("remark_rest[]", ext.remark);
          fd.append("summary_excluded[]", 0);
        } else {
          const price = ext.amount;
          if (price !== 0) {
            if (ext.type === "FOC") {
              fd.append("rest[]", "foc");
            } else {
              fd.append("rest[]", price > 0 ? "surcharge" : "deduct"); // 0: Surcharge, 1: Deduct
            }

            fd.append("price_rest[]", Math.abs(price));
            fd.append("remark_rest[]", ext.remark);
            if (ext.type === "Sim") {
              fd.append("summary_excluded[]", 1);
            } else {
              fd.append("summary_excluded[]", 0);
            }
          }
        }
      });

      if (this.isRefund && this.paymentStatusName !== "Refund-Success") {
        fd.append("refund", true);
        fd.append("refund_reason", this.refundRemark);
      }

      if (this.originalPaymentID === 3 && this.isRefund === false) {
        fd.append("amount", 0);
        fd.append("rest[]", "reject_refund");
        fd.append("price_rest[]", Math.abs(this.total_net()));
        fd.append("remark_rest[]", "Refund Rejected!");
      }

      if (this.isFOC) {
        fd.append("foc", true);
      }

      if (this.isSpecialBooking) {
        fd.append("tour_custom", true);
        fd.append("type", 1);
        fd.append("tour_name", this.tourName || "");
        fd.append("tour_code", this.tour ? this.tour.code : "");
        fd.append("time_type", this.tourTime ? this.tourTime.id : "");
        fd.append("location_id", this.location ? this.location.id : "");
        fd.append("start_time", this.startTime);
        fd.append("end_time", this.endTime);
        fd.append("duration", this.duration);
        fd.append("guaranteed_credit", this.guaranteedCredit || 1);
        fd.append("adult_price", this.tour.adult_price);
        fd.append("children_price", this.tour.children_price);
        fd.append("children_seat_price", this.tour.childrenseat_price);
        fd.append("baby_seat_price", this.tour.babyseat_price);
      }

      this.isLoadingWithoutPaid = true;
      if (this.bookingData) {
        fd.append(
          "status_id",
          this.selectedStatus ? this.selectedStatus.id : this.status
        );
        fd.append("bookid", this.bookingData.bookid);
        fd.append("_method", "patch");
        fd.append("send_confirmation", this.isSendEmail ? 1 : 0);
        try {
          await this.$store.dispatch("updateBooking", fd);
          Notification.success("Successfully updated");
          this.isSubmitRefund = false;
          this.redirectToBooking();
          this.$emit("updated-booking");
        } catch (error) {
          this.$refs.bookingPayModal.isLoading = false;
          this.isLoadingWithoutPaid = false;
          Notification.error(error);
        }
      } else {
        try {
          await this.$store.dispatch("createBooking", fd);
          Notification.success("Successfully created");
          this.$emit("created");
          this.redirectToBooking();
        } catch (error) {
          this.$refs.bookingPayModal.isLoading = false;
          this.isLoadingWithoutPaid = false;
          Notification.error(error);
        }
      }
    },
    async redirectToBooking() {
      this.getTourSummary();

      await this.$store.dispatch("getTourTeamList", {
        tourgroup_id: this.$store.state.booking.bookingDetail.tourgroup_id ||
            this.$route.query.tourgroup_id 
      });
      
      if (this.isRedirect) {
        try {
          await this.$store.dispatch("getTourList", {
            date: moment(this.datepick).format("YYYY-MM-DD"),
            is_include_special_tour: true,
          });

          this.isLoadingWithoutPaid = false;
          this.isShow = false;

          const currentDate = moment(this.datepick).format("YYYY-MM-DD");
          const tourGroupID =
            this.$store.state.booking.bookingDetail.tourgroup_id ||
            this.$route.query.tourgroup_id;

          if (tourGroupID) {
            const toPath = `/bookings/list?date=${currentDate}&tourgroup_id=${tourGroupID}`;
            if (this.$route.fullPath !== toPath) {
              this.$router.push(toPath);
            } else {
              this.dispatchGetBookingList();
            }
          } else {
            const toPath = `/bookings/list?date=${currentDate}`;
            if (this.$route.fullPath !== toPath) {
              this.$router.push(toPath);
            }
          }
        } catch (error) {
          this.isLoadingWithoutPaid = false;
          Notification.error(error);
        }
      } else {
        this.dispatchGetBookingList();
        this.isLoadingWithoutPaid = false;
        this.isShow = false;
      }
      this.clearBookingData();
      this.$emit("saveSuccess", {});
    },
    async deleteBooking(value) {
      try {
        this.isLoadingDelete = true;
        await this.$store.dispatch("deleteBooking", value.id);
        this.dispatchGetBookingList();
        if (this.$refs.confirmModal) {
          this.$refs.confirmModal.closeModal();
        }
        this.isLoadingDelete = false;
        this.isShow = false;
      } catch (error) {
        Notification.error(error);
      }
    },
    async getTourSummary() {
      await this.$store.dispatch("getTourSummary", {
        date: moment(this.datepick).format("YYYY-MM-DD"),
        exclude_unconfirmed: 1,
      });
    },
    async dispatchGetBookingList() {
      await this.$store.dispatch("getBookingList", this.searchParams);
    },
    selectStatus() {
      if (this.selectedStatus.id == 1)
        this.isShowSendEmail = true
      else
        this.isShowSendEmail = false
    },
    selectTC() {
      this.btssource = null;
      this.$store.dispatch("getBookingSourceList", {
        booking_source: this.btsource.code,
      });
      if (this.btsource.code === "D") {
        this.$store.commit("checkBookingAvailability", true);
        this.$store.commit("setBookingAvailabilityMsg", "Available");
      }
      this.checkAvailability()
    },
    checkAgentRatePermission() {
      const isAgentSource = this.btsource && this.btsource.code === "AG";
      return isAgentSource ? this.hasAccess("view_agentRate") : true;
    },
    async selectSource(source, changeInputEmail) {
      if (this.btsource && this.btsource.code === "AG") {
        // Agent
        const params = {
          id: source.agent.id,
        };
        await this.$store.dispatch("getAgentList", params);
        await this.$store.dispatch("getAgentDetail", this.agentList[0].id);
        this.isBookingForAgent = true;
        if (this.agent.email && this.agent.email.trim().length);
        if (changeInputEmail !== false) {
          this.email = this.agent.email;
        }
        this.checkAvailability();
      } else {
        this.isBookingForAgent = false;
      }
    },
    clearBookingData() {
      this.fullname = "";
      this.country = null;
      this.email = "";
      this.pcode = "";
      this.phone = "";
      this.hotel = "";
      this.btsource = null;
      this.btssource = null;
      this.channel = null;
      this.remark = "";
      this.datepick = new Date();
      this.tour = null;
      this.adultCount = 0;
      this.childrenCount = 0;
      this.childrenSeatCount = 0;
      this.babySeatCount = 0;
      this.amount = 0;
      this.totalAmount = 0;
      this.hotelcheck = false;
      this.isLoadingWithoutPaid = false;
      this.extraAmountList = [];
      this.extraHistoryList = [];
      this.error = {};
      this.modalTitle = "New Booking";
      this.bookingHistory = {};
      this.isLoading = false;
      this.isLoadingDelete = false;
      this.selectedStatus = null;
      this.paymentList = [];
      this.maxFiles = CONSTANTS.total_fileuploads;
      this.bookingFile = [];
    },
    calculateTime() {
      let timeMinute = [];
      let count = 0;
      forEach(new Array(24), () => {
        forEach(new Array(4), (v, index) => {
          const extraZero = count.toString().length === 1 ? "0" : "";
          let timeString = "";
          switch (index) {
            case 0:
              timeString = extraZero + count + ":00";
              break;
            case 1:
              timeString = extraZero + count + ":15";
              break;
            case 2:
              timeString = extraZero + count + ":30";
              break;
            case 3:
              timeString = extraZero + count + ":45";
              break;
          }
          timeMinute.push(timeString);
        });
        count++;
      });

      this.timeList = timeMinute;
    },
    calculateDuration(type) {
      if (type === "start_time") {
        this.endTime = null;
        this.duration = null;
      }

      if (this.startTime && this.endTime) {
        const timeDiff = moment(this.endTime, "HH:mm").diff(
          moment(this.startTime, "HH:mm")
        );
        this.duration = moment.utc(timeDiff).format("H:mm");
      }

      this.endTimeList = this.timeList.filter((obj) => {
        return moment(this.startTime, "HH:mm").diff(moment(obj, "HH:mm")) < 0;
      });
      this.checkAvailability();
    },
    async submitFOC(isApprove) {
      const isConfirm = confirm(
        `Are you sure you want to ${isApprove ? "APPROVE" : "REJECT"
        } Free of Charge for this booking?`
      );
      if (!isConfirm) {
        return;
      }

      const fd = new FormData();
      fd.append("_method", "patch");
      fd.append("bookid", this.bookingData.bookid);
      fd.append("is_approve", isApprove);
      this.isLoadingApproveFOC = isApprove;
      this.isLoadingRejectFOC = !isApprove;
      try {
        await this.updateFOC(fd);
        this.isLoadingApproveFOC = false;
        this.isLoadingRejectFOC = false;
        this.isShow = false;
        Notification.success("Successfully updated");
      } catch (error) {
        this.isLoadingApproveFOC = false;
        this.isLoadingRejectFOC = false;
        Notification.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.count {
  width: 45px;
  text-align: center;
  display: inline-block;
}

.people-count svg {
  color: $secondary;
}

.link {
  font-size: 13px;
  color: $white;
  margin-top: 5px;
  display: block;
}

.cancel-btn {
  position: absolute;
  bottom: 0;
}
</style>